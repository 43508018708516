import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/formations.jpg';
import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/website.jpg';
import similar2 from '../../assets/img/services/thumbnail/referencement.jpg';
import similar3 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';

import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/formations.svg';

export default () => (
  <div id="top">
    <Menu />
    <div className={style.menu_mobile}>
      <SEO
        title="Formations | Agence digitale | MDS Digital Agency"
        description="MDS propose des formations à toute personne ou entité désireuse de se former aux métiers du web : SEO, SEA, Wordpress, Google Analytics."
        keywords="formations"
      />
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Formations</h2>
      <p>
        <strong>Vous souhaitez approfondir vos connaissances digitales ?</strong>
      </p>
      <p>
        MDS vous propose des formations aussi bien destinées aux PME qu’aux multinationales, mais
        également à toute personne désireuse de se former aux métiers du web.
      </p>

      <p>
        Nous venons vous former <strong>dans votre entreprise</strong> ou nous vous accueillons{' '}
        <strong>dans nos locaux</strong> situés <strong>à Mons</strong>.
      </p>
      <p>Nos formations:</p>
      <ul>
        <li>
          Référencement naturel (<strong>SEO</strong>)
        </li>
        <li>
          Référencement payant sur <strong>Adwords</strong> (<strong>SEA</strong>)
        </li>
        <li>
          <strong>Wordpress</strong>
        </li>
        <li>
          <strong>Google Analytics</strong>
        </li>
      </ul>

      <CtaSection
        img={cta}
        ctaText="Nous contacter"
        title=" Vous aimez apprendre? Vous souhaitez gérer votre site internet de manière autonome?"
        subtitle="Renseignez-vous sur nos formations personnalisées"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar1} />
          <SimilarCard text="Référencement" url="/services/referencement/" img={similar2} />
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar3} />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
